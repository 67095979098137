import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import TemplateGroupsTableItem from "@/entity/flowable/TemplateGroupsTableItem";
import API from "@/api/flowable/template-groups";

@Options({
    name: "app-flowable-template-groups",
    components: {
        "app-draggable-listview": DraggableListview,
    },
})
export default class TemplateGroups extends BaseTableMixins<TemplateGroupsTableItem> implements Partial<BaseTableInterface<TemplateGroupsTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new TemplateGroupsTableItem();
        this.editModalItem = new TemplateGroupsTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<TemplateGroupsTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new TemplateGroupsTableItem();
        this.editModalItem = new TemplateGroupsTableItem();
        this.editModalItem.groupStatus = 1;
        this.editModalTitle = "添加表单组";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.groupName)) return "表单组名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.groupStatus)) return "表单组状态不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改表单组 (" + this.editModalItem.groupName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.groupId)) return "表单组ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除表单组",
            message: "表单组名称: " + this.editModalItem.groupName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.groupId)) return "表单组ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "表单组排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "表单组状态",
            message: "表单组: " + this.editModalItem.groupName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.groupStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.groupStatus = this.editModalItem.groupStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.groupId)) return "表单组ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }
}
