export default class TemplateGroupsTableItem {
    groupId = 0;
    groupStatus = 0;
    groupName = "";
    groupSortId = 0;
    groupRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
